.list-header {
  background: $off-white;
  border: 1px solid darken($off-white, 7%);
  display: flex;
  justify-content: space-between;
  padding: $s-size $m-size;
}

.list-body {
  margin-bottom: $m-size;
  @media (min-width: $desktop-breakpoint) {
    margin-bottom: $l-size;
  }
}

.list-item {
  border: 1px solid darken($off-white, 7%);
  border-top: none;
  color: $dark-grey;
  display: flex;
  flex-direction: column;
  padding: $s-size;
  text-decoration: none;
  transition: background 0.3s ease;
  &:hover {
    background: $off-white;
  }
  @media (min-width: $desktop-breakpoint) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: $m-size;
  }
}

.list-item--message {
  align-items: center;
  color: $grey;
  justify-content: center;
  padding: $m-size;
  &:hover {
    background: none;
  }
}
.list-item__title {
  margin: 0;
  word-break: break-all;
}

.list-item__sub-title {
  color: $grey;
  font-size: $font-size-small;
}

.list-item__data {
  margin: $s-size 0 0 0;
  @media (min-width: $desktop-breakpoint) {
    margin: 0;
    padding-left: $s-size;
  }
}

/* Table Support */

.table {
  display: flex;
  flex-flow: column nowrap;
  font-size: 0.8rem;
  margin: 0.5rem;
  line-height: 1.5;
  border-bottom: 1px solid #d0d0d0;
  flex: 1 1 auto;
  margin-bottom: 20px;
}

.th {
  display: none;
  font-weight: 600;
  // background-color: #f2f2f2;
  font-size: 1.3rem;
  border-bottom: 1px solid #d0d0d0;
}

.th > .td {
  white-space: normal;
  justify-content: center;
  text-align: center;

  line-height: 1;
  font-weight: bold;
  font-size: 1.2rem;
  align-self: center;
}

.tr {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  text-decoration: none;
}

.item-wrapper:nth-of-type(even) {
  background-color: #f2f2f2;
}

.item-wrapper:nth-of-type(odd) {
  background-color: #ffffff;
}

.td {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0.5em;
  // word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0px;
  white-space: nowrap;

  font-size: 1.4rem;
  justify-content: center;
  color: #000;
  text-decoration: none;
  //   min-height: 35px;
  align-self: center;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 1rem;
  }
}

.thumbnail {
  width: 25px;
  height: 25px;
}

.extra-info.hide {
  display: flex;
  visibility: hidden;
  height: 0;
  // opacity: 0;
  transition: all 0.25s ease-in-out;
  overflow: hidden;
  max-height: 0px;
}

.extra-info.show {
  visibility: visible;
  height: 245px;
  height: auto;
  max-height: 800px;
  transition: all 0.3s ease-in-out;
  padding-bottom: 15px;
  overflow: hidden;
}

.watch .extra-info.show {
  height: 100px;
}

.extra-info .td {
  flex: auto;
  flex-wrap: wrap;
  justify-content: left;
  width: 80%;
  flex-basis: 80%;
  max-width: 80%;
  margin: 0 auto;
}

.extra-info {
  position: relative;
  .table {
    margin: 0px;
    border-bottom: 0px;
    padding: 0px 100px;
    font-size: 1.1rem;
  }
  .tr {
  }
  .col {
    width: 40%;
    margin: 3px 0px;
    text-align: left;
  }
  .col.label {
    width: 60%;
    white-space: pre-wrap;
  }
  .col.full-width {
    width: 100%;

    text-align: left;
    font-style: italic;
    white-space: pre-wrap;
  }

  .name {
    font-weight: bold;
  }
}

.item-wrapper {
  border-bottom: 1px solid #d0d0d0;
  transition: height 2s;
  cursor: pointer;
}

.item-wrapper.open {
  background: $dark-blue;
  transition: background 1s ease;
  .td,
  .black,
  .list-item__sub-title {
    color: #fff;
  }
  .green {
    color: #31ce31;
  }
  .mobile-amount {
    color: #c7c7c7;
  }
}

.mobile-amount {
  width: 100%;
  color: #666;
}
.mobile-name {
  width: 100%;
}

.td.wrap {
  flex-wrap: wrap;
}
.td.flex2 {
  flex: 2;
}
.td.stack {
  flex-direction: column;
}

@media (max-width: 768px) {
  .watch .td {
    text-align: center;
    font-size: 0.3rem;
    font-weight: normal;
  }
  .small.green {
    width: 100%;
    display: block;
    text-align: left;
  }
}
