.avatar {
	display: inline-block;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	overflow: hidden;
}

.avatar img {
	width: 100%;
	/* height: 100%; */
	/* just for doug? */
	width: auto;
	height: 100%;
}

.userName {
	margin-right: 5px;
	@media (max-width: $mobile) {
		font-size: 2em;
	}
}

.header__content .avatar {
	height: 33px;
	width: 35px;
}

.marketInfo .avatar {
	height: 28px;
	width: 28px;
	border-radius: none;
}

.avatar.large {
	border-radius: 0px;
	height: 150px;
	width: 150px;
}
