.form {
	display: flex;
	flex-direction: column;
	> * {
		margin-bottom: $m-size;
	}
	.form__error {
		margin: 0 0 $m-size 0;
		font-style: italic;
	}

	label {
		margin-right: 10px;
	}

	.select {
		@extend .text-input;
	}

	.textarea {
		@extend .text-input;
		height: 10rem;
	}
}
.form {
	.text-input,
	input[type="text"],
	input[type="email"] {
		border: 1px solid #cacccd;
		//height: 50px;
		font-size: $font-size-large;
		font-weight: 300;
		padding: $s-size;
		@media (max-width: 768px) {
			width: 100%;
		}
	}
}

.input-group {
	display: flex;
	flex-direction: column;
	margin-bottom: $m-size;
	.text-input,
	input[type="text"],
	input[type="email"] {
		border: 1px solid #cacccd;
		//height: 50px;
		font-size: $font-size-large;
		font-weight: 300;
		padding: $s-size;
		@media (max-width: 768px) {
			width: 100%;
		}
	}

	@media (min-width: $desktop-breakpoint) {
		flex-direction: row;
		margin-bottom: $l-size;
	}
	@media (max-width: $desktop-breakpoint) {
		flex-flow: column-reverse;
	}
}

.input-group__item {
	margin-bottom: $s-size;

	@media (min-width: $desktop-breakpoint) {
		margin: 0 $s-size 0 0;
	}
}

.dropdown {
	border: 1px solid #ccc;
	position: absolute;
	background: #fff;
	z-index: 10;
	padding: 10px;
	h4 {
		margin: 0px;
	}
	.dropdown-item {
		border-bottom: 1px solid #ccc;
		padding-bottom: 10px;
		margin-bottom: 10px;
	}
}

.renderOption {
	//   border: 3px solid red !important;
	//   color: #000 !important;
	span {
		display: block;
		width: 100%;
	}
}

.autocomplete__symbol {
	color: #000;
	font-weight: bold;
	font-size: 1.3rem;
}
.auto_exchange {
	font-style: italic;
	color: #666;
}

// Material UI
.MuiInputBase-root {
}

.MuiInputBase-root {
	font-size: 1.8rem !important;
}
.MuiInputLabel-outlined {
	font-size: 1.5rem !important;
}
.MuiFormControl-marginNormal {
	width: 100%;
}

.MuiOutlinedInput-input {
	padding: 16.5px 14px !important;
}

.SingleDatePicker {
	width: 100%;
	margin-top: 16px;
	margin-bottom: 16px;
}

.MuiAutocomplete-inputRoot {
	padding-bottom: 0px !important;
	padding-top: 0px !important;
	//padding-right: 65px !important;
}

.SingleDatePickerInput {
	padding: 3px 0px;
	border-radius: 5px;
	&:hover {
		border-color: #000;
	}
}

.adduserWrapper {
	label {
		margin-right: 5px;
	}
}
