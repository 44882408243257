.card-layout {
	margin-top: 30px;
	.card-layout__box {
		position: relative;
	}
}

.member-name {
	font-size: 1.5rem;
	text-transform: capitalize;
	color: #333;
}
.profile-wrapper {
	text-decoration: none;
	color: #333;
	@media (max-width: 800px) {
		width: 50%;
	}
	.small {
		text-align: left;
	}
}

.crown {
	display: none;
}

.button--container {
	padding: 20px 30px;
	padding-bottom: 10px;
}

.smallFont {
	font-size: 79%;
	margin-top: 1px;
}

.MaximumPercentageChampion {
	.crown {
		display: block;
		position: absolute;
		width: 100px;
		height: 60px;
		background: url("/assets/crown.png");
		background-size: contain;
		background-repeat: no-repeat;
		margin-top: -57px;
		margin-left: -1px;
		@media (max-width: $mobile) {
			margin-top: 0px;
			width: 100%;
			height: 50px;
			background-position: center;
			top: -30px;
			margin-left: -5px;
		}
	}
}

.photoWrapper {
	height: 100px;
	width: 100px;
	overflow: hidden;
	@media (max-width: $mobile) {
		height: 150px;
		width: auto;
		display: flex;
		justify-content: center;
		img {
			width: auto;
		}
	}
	//   width: 96px;
	//   height: 96px;
}

.WrongWay {
	.photoWrapper {
		background: url("/assets/debbie.gif");
		background-size: 87%;
		background-repeat: no-repeat;
		background-position: 50% 5px;
		@media (max-width: $mobile) {
			background-repeat: no-repeat;
			background-position: center;
			background-size: 88px 88px;
		}
		img {
			opacity: 1;
			transition: 0.5s all ease-in-out;
		}
	}
}
.WrongWay:hover {
	.photoWrapper {
		img {
			opacity: 0;
			transition: 0.5s all ease-in-out;
			//   @media (max-width: $mobile) {
			//     opacity: 1;
			//   }
		}
	}
}
