.project-list {
	margin-top: 40px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
	grid-gap: 20px;
	width: 100%;
}
.project-list a {
	background-color: #fff;
	padding: 16px;
	border-radius: 6px;
	box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
	text-decoration: none;
	color: inherit;
	border: 1px solid #efefef;
}
.project-list h4 {
	font-size: 0.9em;
	color: var(--heading-color);
}
.project-list p {
	color: var(--text-color);
	font-size: 0.9em;
}
.project-list .assigned-to {
	margin-top: 20px;
	padding-top: 10px;
	border-top: 1px solid #eee;
}
.project-list ul {
	margin: 10px 0;
	display: flex;
}
.project-list li {
	margin-right: 10px;
}
.project-list .avatar {
	width: 30px;
	height: 30px;
}
