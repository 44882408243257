.marketInfo {
	grid-area: market;

	margin-right: -2em;

	.marketInner {
		background: #e0e0e0;
		padding: 10px;
		border-radius: 15px;
		border-top-right-radius: unset;
		border-bottom-right-radius: unset;
		@media (max-width: 1200px) {
			border-radius: 15px;
		}
		@media (max-width: 600px) {
			border-radius: 15px;
		}
	}

	@media (max-width: 1200px) {
		margin-right: 0;

		h2 {
			text-align: left;
			margin: 0px;
			margin-left: 15px;
			display: none;
		}
	}
	h2 {
		text-align: center;
	}

	h3 {
		font-size: 1em;
	}

	.portrait-icon {
		display: inline-block;
		margin: 0px 3px;
	}
	.market-item {
		display: flex;
		align-items: center;
		font-size: 14px;
		.market-name {
			margin-right: 10px;
			@media (max-width: 980px) {
				margin-right: 5px;
			}
		}
		@media (max-width: 576px) {
			font-size: 1em;
		}
	}
	.portrait-icon-wrapper {
		margin-left: auto;
		display: flex;
		.moreInfo {
			// position: absolute;
			// right: 100px;
		}
	}
}

.indciator {
	width: 5px;
	height: 5px;
	border-radius: 50%;
}
.redIndicator {
	background-color: $red;
	margin-left: 4px;
}

.greenIndicator {
	background-color: $green;
}

.update {
	margin-left: 10px;
	font-style: italic;
}

/* Tooltip container */
.tooltip {
	position: relative;
	display: inline-block;
	background: none;
	border: 0px;
	padding: 0px 2px;
	// border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: black;
	color: #fff;
	text-align: center;
	padding: 5px 0;
	border-radius: 6px;

	/* Position the tooltip text - see examples below! */
	position: absolute;
	z-index: 1;
	top: -60px;
	right: 21%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext,
.tooltip:active .tooltiptext {
	visibility: visible;
}

.arrow-down {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid black;
	position: absolute;
	margin-top: 4px;
	right: 5px;
}
