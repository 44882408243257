.content-container {
	margin: 0 auto;
	padding: 0 2em;
	// for flush right market info
	//padding: 0 0 0 1em;
	@media (max-width: 1200px) {
		padding: 0 1em;
	}
	@media (max-width: 980px) {
		padding: 0 1em;
	}

	//   max-width: 100rem;
	.content-wrapper {
		padding-top: 80px;
		@media (max-width: 600px) {
			padding-top: 140px;
			/* adjusting for profile page */
			padding-top: 100px;
		}
	}
}

.table-container {
	//padding: 0px;
}

@media (max-width: 578px) {
	.content-container {
		margin: 0 auto;
		padding: 0 15px;
		// looks bad on iphone 5
		padding: 8px;
		//   max-width: 100rem;
	}
}
