.profile__box {
	display: block;
	flex-direction: column;
}

.profile__wrapper {
	margin-top: 30px;
	.col__wrapper {
		margin-bottom: 30px;
		@media (max-width: 576px) {
			flex-direction: column;
		}
	}
	.col {
		flex: 3;
	}
	.col:first-of-type {
		margin-right: 20px;
		flex-basis: 200px;
		flex-grow: 0;
		@media (max-width: 576px) {
			margin-right: 0px;
		}
	}
}

.col {
	.team-summary {
		h3 {
			@media (max-width: 576px) {
				text-align: center;
			}
		}
	}
}

.profile__wrapper {
	thead {
		@media (max-width: 576px) {
			tr:first-of-type {
				display: none;
			}
		}
	}
}
