* {
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
}

body {
	background: #fff;
	color: $dark-grey;
	font-family: Helvetica, Arial, sans-serif;
	font-size: $m-size;
	line-height: 1.6;
}
.appWrapper {
	padding-bottom: 40px;
}

.is-active {
	font-weight: bold;
}
.capitalize {
	text-transform: capitalize;
}

.center {
	text-align: center !important;
}

.grey {
	color: #555;
}

.small {
	font-size: 10px;
	padding-top: 3px;

	white-space: pre-line;
	text-align: center;
	@media (max-width: $mobile) {
		font-size: 0.8em;
	}
}

.smaller {
	font-size: 9px;
}

.date {
	max-width: 100%;
	white-space: pre-wrap;
	text-align: center;
}

.paddingTop {
	padding-top: 4px;
}
.fullWidth {
	width: 100%;
}

.green {
	color: green;
}
