@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* variables */
:root {
	--heading-color: #444;
	--text-color: #999;
	--primary-color: #8d69f1;
	--highlight-color: #d13267;
	--bg-color: #f4f4f4;
}

/* base styles */
body {
	font-family: Poppins, sans-serif;
	margin: 0;
	font-size: 1.1em;
	background: var(--bg-color);
}
ul,
li,
p,
h1,
h2,
h3,
h4 {
	margin: 0;
	padding: 0;
}
ul {
	list-style-type: none;
}

/* layout */
.page-title {
	font-size: 1em;
	color: var(--heading-color);
	display: inline-block;
}
