.header {
	background: $dark-blue;
	position: fixed;
	width: 100%;
	box-shadow: $box-shadow;
	z-index: 5;
}

.header-container {
	padding: 0px 25px;
}

.header__content {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 0.9rem 0;

	.menu-icon {
		height: 23px;
		width: 23px;
		position: relative;
		top: 0px;
		left: -6px;
		// path {
		// 	stroke: #fff;
		// }
	}
}

.userAvatar {
	border-radius: 50%;
	width: 50px;
}

.menu {
	margin-left: auto;
	//margin-right: 20px;
	text-align: right;
	display: flex;
	align-items: center;
	.menu--link {
		background-color: transparent;
		display: inline-block;
		color: #fff;
		text-decoration: none;
		padding: 3px 20px;
		border-bottom: 1px solid $dark-blue;
		transition: all 0.3s ease;
		border: 0px;
		font-size: 1.6rem;
		font-weight: 400;
		cursor: pointer;
		//border-bottom: 1px solid #fff;
		height: 42px;
		line-height: 50px;
		transition: all 0.6s ease;

		@media (max-width: $mobile) {
			padding: 3px 10px;
			margin-right: 0px;
			margin-left: 10px;
		}
	}
	//   button:last-of-type {
	//     padding-right: 0px;
	//   }
}

.header__title,
.menu--link {
	transition: all 0.6s ease;
	&:hover {
		// border-bottom: 1px solid #fff;
		cursor: pointer;
		svg {
			transition: all 0.6s ease;
			fill: $primary-color;
		}
	}
}

.header__title {
	color: #fff;
	text-decoration: none;
	display: flex;
	align-items: center;
	background: none;
	border: none;
	h1 {
		margin: 0px;
		// text-transform: uppercase;
	}
	.logo {
		width: 50px;
		margin-right: 10px;
	}
}

@media (max-width: $mobile) {
	//   .header {
	//     position: static;
	//   }

	.header__content {
		flex-direction: row;
	}
	.header__title {
		width: 100%;
		text-align: center;
		// justify-content: center;
	}
	.userAvatar {
		position: absolute;
		right: 40px;
	}
	.button--link {
		position: absolute;
		right: -9px;
		top: 4px;
		span {
			//   display: none;
		}
	}
	.menu {
		// margin-right: auto;
		// text-align: center;
		width: 100%;
		justify-content: end;
		margin-right: 5px;
		display: none;
		&.show-mobile {
			position: absolute;
			width: 100%;
			height: 100vh;
			z-index: 1000000;
			background: #000;
			display: flex;
			flex-direction: column;
			justify-content: center;
			top: 85px;
			align-items: center;
			align-content: center;
			text-align: center;
			// alt
			width: 100%;
			padding: 120px 100px;
			padding-top: 70px;
			padding-bottom: 150px;
			left: 0px;
			a,
			button {
				flex: 1;
				font-size: 3em;
				color: #fff;
				background: transparent;
				border: 0px;
				padding: 0px;
				margin: 0px;
				line-height: 3em;
				height: auto;
				display: inline-block;
				transition: all 0.3s ease;
				&:hover {
					// text-decoration: underline;
					transition: all 0.3s ease;
					border-bottom: 1px solid #fff;
				}
				svg,
				.avatar {
					display: none;
				}
			}
		}
	}
	.noBg {
		margin-left: 10px;
	}

	.userAvatar {
		width: 40px;
	}
}

.navbar {
	width: 100%;
	padding: 30px 0;
	box-sizing: border-box;
	margin-bottom: 80px;
}
.navbar ul {
	display: flex;
	margin: 0 auto;
	align-items: center;
	justify-content: flex-end;
}
.navbar .logo {
	font-weight: bold;
	color: var(--heading-color);
	letter-spacing: 1px;
	display: flex;
	align-items: center;
	margin-right: auto;
}
.navbar .logo img {
	margin-right: 10px;
	filter: invert(25%);
	width: 36px;
	margin-top: -8px;
}
.navbar a {
	color: #333;
	text-decoration: none;
	margin-right: 20px;
}

.noBg {
	background: none;
	border: none;
	margin-top: 4px;
	cursor: pointer;

	.avatar {
		border: 2px solid transparent;
		transition: all 0.3s ease;
	}
}
.noBg:hover .avatar {
	border: 2px solid $primary-color;
	transition: all 0.3s ease;
}

.menu .settings--link {
	padding-right: 6px;
	margin-right: 6px;
}

.purchase {
	margin-left: 10px;
	background: transparent;
	color: #fff;
	transition: all 0.3s ease;
	&:hover {
		transition: all 0.3s ease;
		background: $primary-color;
	}
}

.hamburger {
	display: none;
	align-self: center;
	@media (max-width: $mobile) {
		display: flex;
	}

	button {
		background: transparent;
		border: 0px;
		display: flex;
		cursor: pointer;
	}
}
