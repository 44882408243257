// Colors
$dark-grey: #333;
$grey: #666;
$blue: #1c88bf;
$dark-blue: #364051;
$off-white: #f7f7f7;
$dark-red: #d40505;
$green: #31ce31;
$red: red;

$font-size-large: 1.8rem;
$font-size-small: 1.4rem;

// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;

$desktop-breakpoint: 45rem;
$mobile: 576px;

$box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

$mobile: 915px;

$primary-color: #8d69f1;
