.message {
  margin-top: 10px;
  font-size: 90%;
  color: rgb(138, 138, 138);
  text-align: right;
}
.message:hover {
  text-decoration: underline;
  cursor: pointer;
}
