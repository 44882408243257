.profile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.user {
		width: 100%;
		text-align: center;
	}
	.company {
		width: 100%;
	}
}

.profileWrapper {
	padding: 8px;
	.center {
		img {
			border-radius: 50%;
			width: 100px;
		}
		text-align: center;
		h3 {
			margin-left: 0px;
		}
	}
}
.adduserWrapper {
	text-align: left;
	padding: 10px 20px;
}
.userItem {
	button {
		visibility: hidden;
		opacity: 0;
	}
}
.userItem:hover {
	background: #efefef;
	button {
		visibility: visible;
		opacity: 1;
	}
	transition: all 0.5s;
}
