.create-form {
	max-width: 600px;
	margin-top: 30px;
	.MuiInputLabel-root {
		margin: 0px;
		line-height: 1em;
		transform: translate(5px, 14px) scale(1);
	}
	.MuiInputLabel-shrink {
		margin: 24px 0px;
	}
	.MuiOutlinedInput-input {
		height: 0.4em;
		font-weight: 200;
	}
	.MuiOutlinedInput-input {
		padding: 16.5px 14px !important;
	}

	.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon.Mui-focused {
		.MuiInputLabel-formControl {
			transform: translate(20px, -32px) scale(0.75);
		}
	}

	.autocomplete__symbol,
	.auto_security,
	.auto_exchange {
		flex: auto;
		margin-bottom: 0px;
		span {
			margin-bottom: 0px;
		}
	}
	.autocomplete__symbol {
		flex-basis: 75px !important;
		margin-right: 20px;
	}

	.MuiFormLabel-filled {
		transform: translate(19px, -32px) scale(0.75);
	}

	.MuiOutlinedInput-root .MuiAutocomplete-input {
		padding-left: 0px !important;
		padding-top: 19px !important;
		padding-bottom: 15px !important;
		padding-left: 0px;
		font-size: 0.9em;
	}
}

.flex .MuiAutocomplete-root {
	flex: 5;
}

.flex .MuiCircularProgress-root {
	width: 40px;
	height: 40px;
	position: absolute;
	right: 0px;
	width: 30px !important;
	height: 30px !important;
	top: 5px;
	right: 3px;
}
.relative {
	position: relative;
}

.checkbox {
	margin: 0px;
	margin-top: 10px;
	color: rgb(100, 100, 100);
	input {
		width: auto;
		margin-left: 5px;
		margin-top: 5px;
	}
}
