.table-container {
	//   padding-top: $l-size;
	.content-container {
		display: flex;
		align-items: center;
		margin-bottom: 2rem;
		padding: 0px 1em;
		@media (max-width: 980px) {
			display: block;
		}
		.input-group {
			margin-bottom: 0px;
		}
		.table-info {
			padding-bottom: 0px;
			margin-left: 1rem;
		}
	}
	.table-wrapper {
		table {
			margin: 0px;
		}
	}
}

.filterArea {
	//   padding: 8px 5px;
}

.filterArea.hidden {
	height: 0px;
	opacity: 0;
	padding: 0px;
	transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
	//transition-delay: 0.3s;
	* {
		// display: none;
		width: 0px;
		// height: 0px;
		opacity: 0;
		// transition: width 0.6s ease-in-out, opacity 0.3s ease-in-out;
		// transition-delay: 0.3s;
	}
}

.filterArea.show {
	height: 40px;
	height: 100px;
	opacity: 1;

	transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
	position: relative;
	* {
		// display: block;
		// position: absolute;
		width: 100%;
		height: 20px;
		opacity: 1;
		transition: opacity 0.5s ease-in-out;
		transition-delay: 0.3s;
		margin: 11px 10px;
	}
}

.rangeSelector {
	input {
		width: 100% !important;
		margin: 0px !important;
	}
}

.col-title input {
}

table {
	display: flex;
	flex-flow: column nowrap;
	font-size: 0.8rem;
	margin: 0.5rem;
	line-height: 1.5;
	border-bottom: 1px solid #999696;
	flex: 1 1 auto;
	margin-bottom: 20px;
}

th {
	font-weight: 600;
	// background-color: #f2f2f2;
	font-size: 1.3rem;
	border-bottom: 1px solid #d0d0d0;
	flex: 1 0;
	text-align: center;
	padding-bottom: 6px;
	// padding-left: 11px;
	padding-top: 4px;
}

tr:first-of-type th {
	padding-top: 0px;
}

th > .td {
	white-space: normal;
	justify-content: center;
	text-align: center;

	line-height: 1;
	font-weight: bold;
	font-size: 1.2rem;
	align-self: center;
}

tr {
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	text-decoration: none;
	td {
		color: #000;
		transition: color 0.3s;
	}
}

.table-info {
	font-size: 1.5em;
	text-align: left;
	padding-bottom: 25px;
	display: flex;
	span {
		font-weight: 700;
	}
	.filterToggle {
		margin-left: auto;
		background: transparent;
		border: 0px;
		cursor: pointer;
		display: none;
		&:hover {
			cursor: pointer;
		}
	}
}

.mainrow {
	background-color: #f2f2f2;
	border-bottom: 1px solid #d0d0d0;
}
.mainrow.odd {
	background-color: #fff;
}

/* .mainrow:nth-child(n + 3) {
    background-color: #ffffff;
  } */
/* .mainrow:nth-child(odd) {
    background-color: red;
  } */

tr.expanded,
tr.child {
	background-color: #364051 !important;
	border-bottom: 0px;
	//transition: background 1s ease;
	td {
		color: #ffffff;
		transition: color 0.3s;
	}
	.green {
		color: #31ce31;
	}
}

td {
	display: flex;
	flex-flow: row nowrap;
	flex-grow: 1;
	flex-basis: 0;
	padding: 0.5em;
	// word-break: break-word;
	// overflow: hidden;
	text-overflow: ellipsis;
	min-width: 0px;
	white-space: nowrap;

	font-size: 1.4rem;
	justify-content: center;
	color: #000;
	text-decoration: none;
	//   min-height: 35px;
	align-self: center;
	//   @media (max-width: 768px) {
	//     text-align: center;
	//     font-size: 1.3rem;
	//   }
}

.child {
	height: 0px;
	//background-color: transparent;
	background-color: #364051;
	overflow: hidden;
	color: #ffffff;
	transition: all 0.1s ease-in-out;
	padding-bottom: 0px;
	padding-top: 0px;
}

.expanded + .child {
	background-color: #364051;
	height: auto;
	//max-height: 800px;
	transition: all 0.3s ease-in-out;
	border-bottom: 1px solid #d0d0d0;
	position: relative;
	padding-bottom: 50px;
	padding-top: 20px;
}

.sub-items {
	color: #fff;
	display: flex;
	flex-direction: row;
	width: 100%;
	@media (max-width: 980px) {
		flex-direction: column;
	}
	@media (max-width: 600px) {
		flex-direction: column;
	}
	.col {
		flex: auto;
		text-align: left;
		// padding: 5px 15px;
		p {
			white-space: pre-wrap;
		}
		a {
			color: #fff;
		}
	}
}
.item {
	width: 100%;
	display: flex;
	padding: 5px 15px;
	flex-direction: row;
	align-items: center;
	@media (max-width: 578px) {
		flex-wrap: wrap;
		padding: 5px 0px;
	}
	span {
		text-transform: capitalize;
	}
}
.spacer {
	margin-left: 5px;
	margin-right: 5px;
}
.label {
	flex-basis: 150px;
	flex-basis: 250px;
}

.example-appear {
	opacity: 0.01;
}

.input-group {
	margin-bottom: 1rem;
}

.example-appear.example-appear-active {
	opacity: 1;
	transition: opacity 0.5s ease-in;
}

.show-mobile {
	display: none;
}

@media (max-width: 915px) {
	// @media (max-width: 768px) {
	.hide-mobile {
		display: none;
	}
	.show-mobile {
		display: block;
	}
}

.rangeSelector {
	flex-direction: column;
}

.multi-cell {
	span {
		width: 100%;
		display: block;
	}
}

.show-mobile {
	td,
	th {
		font-size: 1.4rem;
		flex: auto;
		flex: 1.4 1;
		text-align: center;
		justify-content: center;
		// flex-basis: 123px;
	}
	td {
		.multi-cell {
			font-size: 1.5rem;
		}
	}
	td:first-of-type {
		.multi-cell {
			font-size: 1.3rem;
		}
	}
	th {
		padding-left: 0px;
	}
	td:first-of-type,
	th:first-of-type {
		font-size: 1.3rem;
		flex: auto;
		flex: 1;
		text-align: left;
		justify-content: left;
		// flex-basis: 41px;
		// flex-basis: 115px;
		padding-left: 3px;
		span {
			color: #666;
		}
	}
	th:last-of-type {
		padding-right: 20px;
	}
	td:last-of-type,
	th:last-of-type {
		text-align: right;
		justify-content: flex-end;
		flex-basis: 57px;
		padding-right: 15px;
		@media (max-width: 576px) {
			flex-basis: unset;
			padding-right: 3px;
			flex: 1;
		}
	}
	.sub-items {
		text-align: left;
		.item {
			justify-content: flex-start;
		}
	}

	.thumbnail {
		width: 30px;
		height: 30px;
	}
	.child {
	}
	.expanded {
		td:first-of-type {
			span {
				color: #fff;
			}
		}
	}
}

.inner-table-wrapper {
	// padding: 0px 1em;
}
//@media (max-width: 980px) {
@media (max-width: 768px) {
	.table-container {
		padding: 0px;
	}
	table {
		margin: 0px;
	}
}

@media (max-width: 578px) {
	th .col-title:after {
		margin-left: 0px !important;
	}
}

// th .col-title:after {
//   content: '▲';
//   transform: rotate(0);
//   transition: transform 0.2s;
//   opacity: 0.4 !important;
//   position: absolute;
//   right: 0px;
//   top: 0px;
//   font-size: 10px;
// }
// th .col-title:before {
//   content: '▼';
//   transform: rotate(0);
//   transition: transform 0.2s;
//   opacity: 0.4;
//   position: absolute;
//   right: 0px;
//   top: 10px;
//   font-size: 10px;
// }

th.sort-asc .col-title:after {
	content: "▲";
	transform: rotate(0);
	transition: transform 0.2s;
	opacity: 1 !important;
	//position: absolute;
	// right: 0px;
	float: right;
	font-size: 10px;
}
th.sort-asc .col-title:before,
th.sort-desc .col-title:before {
	content: "";
	position: absolute;
}
th.sort-desc .col-title:after {
	content: "▲";
	transform: rotate(180deg);
	transition: transform 0.2s;
	opacity: 1 !important;
	//margin-top: -3px;
	float: right;
	font-size: 10px;
	//top: -3px;
}
th {
	position: relative;
}

// for stocks 2
.sub-items {
	display: flex;
	flex-wrap: wrap;
	.avatar {
		margin-right: 10px;
	}
}

.color1 {
	// background-color: red;
}
thead tr:first-of-type {
	th:nth-of-type(1) {
		flex: 3;
		background: #d9e8ff;
	}
	th:nth-of-type(2) {
		flex: 3;
		background: #ebffe4;
	}
	th:nth-of-type(3) {
		flex: 5;
		background: #fff7e2;
	}
	th {
		div {
			position: relative;
			top: 3px;
			font-size: 90%;
			font-weight: normal;
		}
	}
}
// .show-mobile thead tr:first-of-type {
// 	th:nth-of-type(1) {
// 		flex: 1;
// 		background: #d9e8ff;
// 	}
// 	th:nth-of-type(2) {
// 		flex: 1;
// 		background: #ebffe4;
// 	}
// 	th:nth-of-type(3) {
// 		flex: 2.5;
// 		background: #fff7e2;
// 	}
// }

@media (max-width: 800px) {
	thead tr:first-of-type {
		th:nth-of-type(1) {
			flex: 1.5;
			background: #d9e8ff;
		}
		th:nth-of-type(2) {
			flex: 0.5;
			background: #ebffe4;
		}
		th:nth-of-type(3) {
			flex: 1;
			background: #fff7e2;
		}
	}
}
@media (max-width: 600px) {
	thead tr:first-of-type {
		th:nth-of-type(1) {
			flex: 1;
			background: #d9e8ff;
		}
		th:nth-of-type(2) {
			flex: 1;
			background: #ebffe4;
		}
		th:nth-of-type(3) {
			flex: 2;
			background: #fff7e2;
		}
	}
}

// non-flex table. sub component is fixed height right now..

// .inner-table-wrapper {
// 	table {
// 		display: table;
// 		width: 100%;
// 		border-collapse: collapse;
// 	}
// 	tr {
// 		display: table-row;
// 		&.child {
// 			height: 0px;
// 			overflow: hidden;
// 			display: table-row;
// 			td {
// 				padding: 0px;
// 			}
// 			.sub-items {
// 				height: 0px;
// 				overflow: hidden;

// 				transition: all 0.6s;
// 			}
// 		}
// 	}

// 	th {
// 		// border: 1px solid black;
// 	}

// 	td {
// 		display: table-cell;
// 		text-align: center;
// 	}
// 	.expanded + .child {
// 		display: table-row !important;

// 		.sub-items {
// 			height: 250px;

// 			transition: all 0.6s;
// 		}
// 	}
// 	thead tr:first-of-type {
// 		th {
// 			padding-top: 2px;
// 			padding-bottom: 1px;
// 			font-size: 0.9rem;
// 			// font-style: italic;
// 			font-weight: normal;
// 			padding-top: 3px;
// 		}
// 		th:nth-of-type(1) {
// 			// border-bottom: 1px solid red;
// 			// border-top: 1px solid red;
// 			background: rgb(229, 200, 255);
// 		}
// 		th:nth-of-type(2) {
// 			// border-bottom: 1px solid blue;
// 			// border-top: 1px solid blue;
// 			background: rgb(185, 197, 255);
// 		}
// 		th:nth-of-type(3) {
// 			// border-bottom: 1px solid blue;
// 			// border-top: 1px solid blue;
// 			background: rgb(218, 255, 218);
// 		}
// 	}
// 	// selector
// 	th.sort-asc .col-title:before,
// 	th.sort-desc .col-title:before,
// 	th.sort-asc .col-title:after,
// 	th.sort-desc .col-title:after {
// 		position: absolute;
// 		margin-left: 8px;
// 	}
// }
