.user-list {
	width: 250px;
	min-width: 250px;
	padding: 10px 20px;
	box-sizing: border-box;
	background: #fbfbfb;
	color: var(--heading-color);
	@media (max-width: $mobile) {
		width: 100%;
		label span {
			text-align: left;
			text-transform: capitalize;
		}
	}
}
.user-list h2 {
	text-align: right;
	margin-bottom: 20px;
	padding-bottom: 10px;
	border-bottom: 1px solid #eee;
	font-size: 1.2em;
	@media (max-width: $mobile) {
		text-align: center;
		font-size: 3em;
	}
}

.user-list h4 {
	text-align: center;
	margin-bottom: 20px;
	padding-bottom: 10px;
	border-bottom: 1px solid #eee;
	text-transform: capitalize;
}
.user-list .user-list-item {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 15px auto;
	@media (max-width: $mobile) {
		justify-content: center;
	}
}

.user-list.center .user-list-item {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 15px auto;
}

.user-list .avatar {
	margin-left: 10px;
	width: 30px;
	height: 30px;
}

.user-list .online-user {
	display: inline-block;
	margin-right: 10px;
	width: 12px;
	height: 12px;
	background: #0ebb50;
	border-radius: 50%;
	margin-top: 2px;
}
