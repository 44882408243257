.App {
	display: flex;
}
.App .container {
	flex-grow: 1;
	/* padding: 0 60px; */
	padding-bottom: 60px;
}

.padding-top {
	padding-top: 30px;
}

.flex {
	display: flex;
}
/* .App .main-content {
	padding-top: 75px;
} */

body {
	@apply bg-gray-800;
}

.ticker-view {
	background: #000;
	height: 100%;
	margin: auto;
	display: flex;
	flex-direction: row-reverse;
	overflow: hidden;
	font-size: 56px;
	position: relative;
	color: white;
	/* color: black; */
}

.number-placeholder {
	visibility: hidden;
}

.ticker-column-container {
	position: relative;
}

.ticker-column {
	position: absolute;
	height: 1000%;
	bottom: 0;
}

.ticker-digit {
	height: 10%;
}

.ticker-column.increase {
	animation: pulseGreen 500ms cubic-bezier(0.4, 0, 0.6, 1) 1;
}

.ticker-column.decrease {
	animation: pulseRed 500ms cubic-bezier(0.4, 0, 0.6, 1) 1;
}

@keyframes pulseGreen {
	0% {
		color: rgb(255, 255, 255);
		color: rgb(6, 128, 6);
	}
	50% {
		/* @apply text-green-500; */
		color: rgb(6, 128, 6);
	}
	100% {
		color: rgb(255, 255, 255);
	}
}

@keyframes pulseRed {
	0%,
	100% {
		color: red;
	}

	50% {
		/* @apply text-red-500; */
		color: red !important;
	}
}
