.page-header {
  background: $off-white;
  padding: $l-size 0;
  padding-top: 100px;
}

.page-header__actions {
  margin-top: $m-size;
}

.page-header__title {
  font-weight: 300;
  margin: 0;
  span {
    font-weight: 700;
  }
}

@media (max-width: 578px) {
  .page-header {
    //padding-top: 150px;
    // margin-bottom: 2em;
    padding-bottom: 1em;
    margin-bottom: -1px;
    /* weird gap that game to be with dark mode */
  }
  .page-header__title {
    text-align: center;
  }
}

.header {
  .white {
    background: none;
    color: #fff;
    border: none;
    padding: 8px 20px;
    border-bottom: 1px solid #364051;
    transition: all 0.3s ease;
    &:hover {
      border-bottom: 1px solid #fff;
      transition: all 0.3s ease;
    }
    svg {
      //fill: #fff;
      path {
        // stroke: #fff;
      }
    }
  }
  .header__content .MuiSvgIcon-root {
    top: 0px;
    left: 0px;
  }
  .userItem {
    display: flex;
    align-items: center;
    padding: 3px 20px;
    text-align: left;
    div {
      flex: auto;
    }
    button {
      padding: 8px;
    }
  }
  .medium-icon {
    width: 23px;
    height: 23px;
  }
  .close-button {
    margin-left: auto;
  }
  h3 {
    margin-left: 20px;
  }
  .adduserWrapper {
    button {
      margin-left: 10px;
      position: relative;
      top: -2px;
    }
    input {
      height: 32px;
    }
  }
}
