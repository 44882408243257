.button {
	background: $blue;
	border: none;
	color: #fff;
	display: inline-block;
	font-weight: 300;
	font-size: $font-size-large;
	line-height: 1;
	padding: $s-size;
	text-decoration: none;
}

.icon-button {
	background: none;
	border: none;
	cursor: pointer;
	// border: 1px solid #333;
	border-radius: 4px;
	&.active {
		// border: 1px solid #333;
		background: #efefef;
		border-radius: 4px;
	}
	svg {
		width: 20px;
		height: 20px;
		position: relative;
		top: 1px;
	}
	&.top-adjust svg {
		position: relative;
		top: 2px;
	}
}

.button.href--link {
	font-size: 1.3rem;
	width: 100%;
	margin-bottom: 15px;
}

.formButton {
	margin-left: 4px;
	border-radius: 50%;
	width: 45px;
	height: 45px;
	margin-top: 2px;
	background: $blue;
	transition: all 0.5s ease;
	cursor: pointer;
	svg {
		stroke: #fff;
		fill: #fff;
		position: relative;
		top: 3px;
	}
	path {
		stroke: #fff;
	}
	&:hover {
		background: $dark-blue;
		transition: all 0.5s ease;
	}
}

.button--link {
	background: none;
	cursor: pointer;
	font-size: 16px;
	position: relative;
	top: -3px;
	font-weight: 400;
	margin-left: 10px;
	padding-bottom: 9px;
	border-bottom: 1px solid $dark-blue;
	transition: all 0.3s ease;
	&:hover {
		border-bottom: 1px solid #fff;
		transition: all 0.6s ease;
	}
}

.button--secondary {
	background: #888;
}

.add_position {
	position: fixed;
	bottom: 20px;
	right: 10px;
	border-radius: 50%;
	height: 45px;
	width: 45px;
	background: $blue;
	opacity: 0.7;
	transition: opacity 0.3s;
	svg {
		position: relative;
		top: 2px;
		left: 2px;
	}
	&:hover {
		opacity: 1;
		transition: opacity 0.3s;
	}
}

.edit-button {
	position: absolute;
	top: 10px;
	right: 30px;
	color: #fff;
	border: 1px solid #fff;
	border-radius: 50%;
	width: 25px;
	height: 25px;

	svg {
		stroke: $blue;
		width: 18px;
		height: 18px;
		transition: all 0.3s;
		position: relative;
		top: 3px;
		left: 2px;
	}
	&:hover {
		background: #fff;
		color: $dark-blue;
	}
}

.button.disabled {
	background: #ccc;
}

.btn {
	background: #fff;
	padding: 8px 12px;
	border-radius: 4px;
	color: var(--primary-color);
	cursor: pointer;
	font-size: 1em;
	border: 1px solid var(--primary-color);
	text-decoration: none;
}
.btn:hover {
	color: #fff;
	background-color: var(--primary-color);
}

/* forms */
label {
	display: block;
	margin: 24px auto;
}
label span {
	display: block;
	margin-bottom: 6px;
}
input,
textarea,
select {
	padding: 8px 6px;
	font-size: 1em;
	color: #777;
	width: 100%;
	box-sizing: border-box;
	border: 1px solid #ddd;
	border-radius: 4px;
}
textarea {
	min-height: 160px;
}
.error {
	color: red;
	background: pink;
	border: 1px solid red;
	border-radius: 4px;
	padding: 8px;
	margin: 10px 0;
}
