.box-layout {
	background: url("/assets/bg.jpg");
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
}

.box-layout__box {
	background: fade-out(white, 0.2);
	border-radius: 3px;
	text-align: center;
	width: 25rem;
	padding: $l-size $m-size;
}
.box-layout__box:nth-of-type(2) {
	margin-left: 10px;
}

.box-layout__title {
	margin: 0px 0px $m-size 0;
	line-height: 1;
}

.card-layout {
	display: grid;
	grid-template-columns: 4fr 2fr;
	grid-template-areas: "users market";

	gap: 10px;
	grid-auto-rows: minmax(100px, auto);
	@media (max-width: 1000px) {
		grid-template-columns: 3fr 2fr;
	}
	@media (max-width: 800px) {
		grid-template-areas: "users" "market";
		grid-template-columns: 2fr 1fr;

		.section-wrapper {
			display: flex;
			flex-direction: column;
			section {
				margin: 0px 1em;
			}
		}
	}

	@media (max-width: 800px) {
		grid-template-columns: 1fr;
		.marketInfo {
			max-width: 315px;
			max-width: 100%;
		}
	}

	@media (max-width: 600px) {
		grid-template-columns: 1fr;
		.marketInfo {
			max-width: 100%;
		}
	}
	//flex-wrap: wrap;
	.profiles {
		grid-area: profiles;
	}
	.users {
		grid-area: users;
	}
}

.profile-layout {
	display: flex;
	flex-wrap: wrap;
	margin-top: 30px;
}

.profile-wrapper:first-of-type {
	.card-layout__box {
		margin-left: 0px;
	}
}

.card-layout__box {
	background: rgba(255, 255, 255, 0.8);
	border-radius: 7px;
	text-align: center;
	// width: 25rem;
	padding: 1.6rem 1.1rem;
	border: 1px solid #efefef;
	margin: 5px;
	display: flex;
	flex-wrap: wrap;
}

.position-name {
	font-size: 1.1rem;
	text-transform: capitalize;
}
.info {
	text-align: left;
	padding-left: 15px;
	font-size: 1.5rem;
	line-height: 1.2;
	font-weight: bold;
}
.green {
	color: green;
}
.red {
	color: red;
}
.black {
	color: #000;
}
.sub-info {
	margin-bottom: 8px;
	font-weight: normal;
}
.today-info {
}
h3 {
	margin-bottom: 4px;
	margin-top: 5px;
	font-weight: normal;
	text-align: left;
}

.begin {
	display: block;
	position: absolute;
	background: #fff;
	color: #000;
	width: 75vw;
	text-align: center;

	padding: 1px;
	font-style: italic;
	img {
		width: 100%;
	}
}

#app {
	//   padding-bottom: 100px;
}

/* Badges */

.badge-wrapper {
	font-size: 11px;
	text-align: center;
	font-weight: bold;
	color: #515480ad;
	border-radius: 15px;
	background: #e0e0e0;
	margin: 5px;
	&.red {
		// background: $dark-red;
		// color: $dark-red;
	}
}

@keyframes fire {
	0% {
		border-color: red;
	}
	50% {
		border-color: white;
	}
	100% {
		border-color: red;
	}
}

.onfire .card-layout__box {
	border: 1px solid red;
	box-sizing: border-box;
	animation-name: fire;
	animation-duration: 4s;
	animation-iteration-count: infinite;
}

// @keyframes fireBG {
//   0% {
//     background-image: none;
//   }
//   50% {
//     background-image: url("https://thumbs.gfycat.com/DeadlyLimpingDwarfmongoose-max-1mb.gif");
//   }

//   100% {
//     background-image: none;
//   }
// }

.onfire:hover {
	//   animation-name: fireBG;
	//   animation-duration: 3s;
	//   animation-iteration-count: 1;
	//   background: url("https://thumbs.gfycat.com/DeadlyLimpingDwarfmongoose-max-1mb.gif");
	//   background-repeat: no-repeat;
	//   background-size: 140%;

	//   -webkit-transition: background 0.2s ease-in-out;
	//   transition: background 0.2s ease-in-out;
}

@media (max-width: 576px) {
	.profile-wrapper {
		// width: 50%; ? was cutting of a bit
		margin-bottom: 10px;
		width: 50%;
	}
	.card-layout__box {
		flex-direction: column;
		padding: 7px;
		padding-bottom: 5px;
		margin: 0px;
	}
	.profile-wrapper:nth-of-type(2n) {
		.card-layout__box {
			margin-left: 10px;
		}
	}
	.info {
		display: flex;
		padding-left: 0px;
		width: 100%;
		font-size: 11px;
	}
	.sub-info {
		padding: 0px;
		// font-size: 90%;
		font-size: 1.3em;
		flex: 1;
		text-align: center;
		align-self: flex-start;
		padding: 5px;
		.number {
			font-size: 1.2em;
		}
		.small {
			font-size: 0.7em;
		}
	}

	.badge-wrapper {
		font-size: 10px;
	}
	h3 {
		// font-size: 15px;
		text-align: center;
		font-size: 1.3em;
	}
	.col {
		h3 {
			text-align: left;
		}
	}
}

.watch-list-input {
	display: flex;
	justify-content: flex-end;
}
.form.watchForm {
	flex-direction: row;
	@media (max-width: 768px) {
		width: 100%;
	}
}

.watch-group {
	flex: auto;
}

// Columns

.col__wrapper {
	display: flex;
	.col {
		flex: auto;
	}
}
