.sidebar {
	width: 300px;
	min-width: 300px;
	/* background: var(--primary-color); */
	min-height: 100vh;
	box-sizing: border-box;
	position: relative;
	color: #fff;
}

.sidebar-right {
	background: #fbfbfb;
	padding-top: 70px;
	width: 250px;
	min-height: 100vh;
	position: fixed;
	right: 0px;
	transition: all 0.25s;
	.buttonWrapper {
		margin-top: 20px;
		.btn:first-of-type {
			margin-right: 20px;
		}
	}
	@media (max-width: $mobile) {
		right: 0px;
		display: block;
	}
}

.sidebar-right h3 {
	text-align: center;
	background: #364051;
	display: inline-block;
	padding: 2px 4px;
	padding-top: 5px;
	border-radius: 4px;
	color: #fff;
	font-size: 1em;
}

.sidebar-right.hidden {
	right: -250px;
	transition: all 0.25s;
	@media (max-width: $mobile) {
		right: 0px;
		display: none;
	}
}

.sidebar-content {
	position: fixed;
	width: inherit;
}
.sidebar .user {
	font-weight: bold;
	text-align: center;
	letter-spacing: 1px;
	padding: 40px 30px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.sidebar .links {
	margin-top: 80px;
	margin-left: 20px;
}
.sidebar .links li {
	margin-top: 10px;
}
.sidebar .links a {
	display: flex;
	padding: 10px;
	text-decoration: none;
	width: 100%;
	color: #fff;
	box-sizing: border-box;
}
.sidebar .links img {
	margin-right: 10px;
	filter: invert(100%);
}
.sidebar .links a.active {
	color: #555;
	background: var(--bg-color);
	border-radius: 20px 0 0 20px;
}
.sidebar .links .active img {
	filter: invert(40%);
}

.control {
	text-align: center;
	@media (max-width: $mobile) {
		padding: 0px 2em;
	}
}

.user-sidebar,
.settings {
	text-align: center;
	&.visible {
		@media (max-width: $mobile) {
			width: 100%;
		}
	}
}

.user-sidebar .avatar {
	margin-top: 30px;
	width: 80px;
	height: 80px;
	@media (max-width: $mobile) {
		height: 180px;
		width: 180px;
		img {
			width: auto;
			height: 100%;
		}
	}
}

.gray {
	color: #9b9b9b;
}

@media (max-width: 578px) {
	.makeStyles-drawerPaper-7,
	.jss7 {
		width: 100vw !important;
		// weird bug on staging this is set to 450px. sometimes different classes are rendered.
		.profileWrapper {
			i {
				text-transform: uppercase;
			}
		}
	}

	.userItem {
		text-align: left;
	}
	.adduserWrapper {
		.text-input,
		.select,
		.textarea,
		input[type="text"],
		input[type="email"] {
			width: 52%;
		}
	}
}

.App .sidebar-right {
	@media (max-width: 578px) {
		padding-top: 100px;
	}
}
